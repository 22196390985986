import React from 'react'

export const handleClick = (url, clinic, openDay) => {

    var type = url.includes('+3519') ? 'Móvel' : url.includes('+3512') ? 'Fixo' : ''

    var clinicToSend = clinic

    clinic = clinic.toLowerCase()

    if (clinic.includes('aveiro')) {
        clinicToSend = 'Clínica Aveiro'
    } else if (clinic.includes('porto')) {
        clinicToSend = 'Clínica Porto'
    } else if (clinic.includes('águeda')) {
        clinicToSend = 'Clínica Águeda'
    } else if (clinic.includes('viseu')) {
        clinicToSend = 'Clínica Viseu'
    } else if (clinic.includes('oliveira')) {
        clinicToSend = 'Clínica Oliveira de Azeméis'
    }

    if (openDay) {
        clinicToSend = 'Chamada Open Day'
    }


    if (typeof window !== "undefined") {
        window.gtag('event', 'chamadas', {
            tipo_chamada: type,
            clinica: clinicToSend
        })
        window.open(url, '_self', 'noreferrer')
    }

    //console.log("handlePhone called with:", { url, clinic, openDay, type, clinicToSend });

}